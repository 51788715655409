export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    return items.slice(0, startIndex + pageSize)
}

export const PAGE_SIZE = 4

export const postFetcher = (url) => fetch(url).then((res) => res.json())

export const defaultInfiniteScrollOptions = {
    initialSize: 2,
    revalidateAll: true
}
