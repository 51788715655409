import Posts from '@/components/Posts'
import TopStories from '@/components/TopStories'
import Section from '@/src/components/Section/Section'
import { getDictionary } from '@/src/dictionary'
import { memo } from 'react'

interface HomeDesktopProps {
    isLoading: boolean
    setSize: any,
    size: number,
    isEnd: boolean,
    topStoriesPosts: any[],
    otherPosts: any[],
}

const HomeDesktop = ({
    isLoading,
    setSize,
    size,
    isEnd,
    topStoriesPosts,
    otherPosts
}: HomeDesktopProps) => {

    return (
        <>
            <Section
                key={getDictionary('topStories')}
                title={getDictionary('topStories')}
            >
                <TopStories posts={topStoriesPosts} />
            </Section>
            <Section
                key={getDictionary('postsName')}
                title={getDictionary('postsName')}
            >
                <Posts posts={otherPosts} isEnd={isEnd} setSize={setSize} size={size} isLoading={isLoading} />
            </Section>
        </>
    )
}

export default memo(HomeDesktop)
