import React from 'react'
import PostCard from '@/components/Posts/PostCard'
import { isHot97 } from '@/src/dictionary'

interface TopStoriesProps {
    posts: any
}

export default function TopStories({ posts }: TopStoriesProps) {
    return (
        <div className='grid grid-flow-row gap-0 pb-0 md:grid-flow-col auto-rows-[minmax(0,_350px)]'>
            {
                posts ? posts.map((post: any, index: number) => {
                    if (index === 0) {
                        return (
                            <div key={`top-${index}`} className='row-span-3 h-full md:me-8'>
                                <PostCard post={post}
                                    imageWrapperStyle='h-full w-full transition-opacity opacity-0 duration-[1s] object-cover'
                                    articleStyle={`${isHot97() ? 'bg-black text-white' : 'bg-white text-black'} border-none flex flex-col justify-start max-w-full items-center p-0 h-full`}
                                    contentLength={350}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={`storie-${index}`} className='mb-8 last:mb-0'>
                                <PostCard post={post}
                                    articleStyle={'flex flex-col me-0 bg-black md:flex-row h-full'}
                                    imageWrapperStyle={'w-4/12 object-contain'}
                                    infoStyle={`flex flex-col flex-1 p-6 pt-8 pb-8 pl-8 pr-8 ${isHot97() ? 'text-white bg-black' : 'text-black bg-white'}`}
                                    setContent={false}
                                />
                            </div>
                        )
                    }
                }) : null
            }
        </div>
    )
}
