import PostCard from '@/components/Posts/PostCard'
import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isHot97 } from '../dictionary'
const Ad = dynamic(() => import('@/src/components/Ad'), { ssr: false })

interface HomeMobileProps {
    setSize: any,
    size: number,
    isEnd: boolean,
    posts: any[]
}

export default function HomeMobile({
    setSize,
    size,
    isEnd,
    posts,
}: HomeMobileProps) {

    function getPost(post: any) {
        return (
            <div key={post.id}
                className='relative'>
                <PostCard post={post}
                    articleStyle={`${isHot97() ? 'bg-black' : 'bg-white'} border-none flex flex-col justify-start max-w-full items-center p-0`} />
            </div>
        )
    }

    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true)
        });
        return () => clearTimeout(timer);
    }, []);

    return isShown ?
        <InfiniteScroll
            next={() => setSize(size + 1)}
            hasMore={!isEnd}
            loader={'Loading'}
            dataLength={posts?.length}
        >
            {
                posts?.map((post, index) => {
                    return (
                        <React.Fragment key={Math.random()}>
                            {getPost(post)}
                            {
                                (index + 1) % 4 === 0 ?
                                    <Ad id={'post-ad-' + index}
                                        shouldAddNew
                                        pos='incontent1'
                                        className='flex w-full text-center static'
                                        closeButton={true}
                                        category={undefined}
                                        postId={undefined} />
                                    : null
                            }
                        </React.Fragment>
                    )
                })
            }
        </InfiniteScroll>
        :
        <div className='w-[100vw]'>
            <div className="flex flex-col gap-4 w-full px-12 py-6">
                <div className="skeleton h-80 w-full"></div>
                <div className="skeleton h-8 w-full"></div>
                <div className="skeleton h-8 w-full"></div>
                <div className="skeleton h-8 w-28"></div>
            </div>
            <div className="flex flex-col gap-4 w-full px-12 py-6">
                <div className="skeleton h-80 w-full"></div>
                <div className="skeleton h-8 w-full"></div>
                <div className="skeleton h-8 w-full"></div>
                <div className="skeleton h-8 w-28"></div>
            </div>
        </div>

}